<template>
  <div class="User">
    <div class="c-box">
      <p style="margin-top:30px">
        <i class="el-icon-user icon" />
        <span>我的账号</span>
      </p>
      <el-row v-show="step==1" class="line">
        <el-col :span="3">
          <div class="userimg">
            <img :src="userimg" alt="">
          </div>
        </el-col>
        <el-col :span="21">
          <el-row>
            <el-col :span="8">
              <p>
                <i class="el-icon-office-building icon" />
                <span>公司名称：{{ data.company }}</span>
              </p>
            </el-col>
            <el-col :span="8">
              <p>
                <i class="el-icon-user icon" />
                <span>联系人：{{ data.nameCn }}</span>
              </p>
            </el-col>
            <el-col :span="8">
              <p>
                <i class="el-icon-mobile-phone icon" />
                <span>手机号：{{ data.phone }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <p>
                <i class="el-icon-time icon" />
                <span>状态：{{ status }}</span>
              </p>
            </el-col>
            <el-col :span="8">
              <p>
                <i class="el-icon-location-outline icon" />
                <span>联系地址：{{ data.address }}</span>
              </p>
            </el-col>
            <el-col :span="8">
              <p>
                <i class="el-icon-postcard icon" />
                <span>营业执照：<a :href="data.businessLicenseUrl" target="_blank">查看</a></span>
              </p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <p>
                <i class="el-icon-set-up icon" />
                <span>结算方式：<span>{{ data.clearingWayName }}</span></span>
              </p>
            </el-col>
          </el-row>
        </el-col>
        <p style="text-align:center;">
          <el-button class="ebuton" type="primary" round icon="el-icon-edit-outline" @click="initSearch">编辑</el-button>
        </p>
      </el-row>
      <el-row v-show="step==2" class="line" :model="searchData">
        <el-form :inline="true" :model="searchData" class="demo-form-inline">
          <el-col :span="8">
            <p>
              <span>公司名称：{{ data.company }}</span>
            </p>
            <p>
              <el-form-item label="联系人">
                <el-input v-model="data.nameCn" placeholder="请输入" height="36px" />
              </el-form-item>
            </p>
          <!-- <p>
          <el-form-item label="结算方式" >
            <el-select v-model="data.clearingWay" placeholder="请输入" height="36px">
              <el-option label="票结" :value="1"></el-option>
              <el-option label="月结" :value="2"></el-option>
            </el-select>
          </el-form-item>
          </p> -->
          </el-col>
          <el-col :span="8">
            <p>
              <span>登录名：{{ data.userName }}</span>
            </p>
            <p>
              <el-form-item label="联系地址">
                <el-input v-model="data.address" type="textarea" :rows="2" placeholder="请输入" />
              </el-form-item>
            </p>
          </el-col>
          <el-col :span="8">
            <p>
              <span>手机号：{{ data.phone }}</span>
              <el-button class="phone-demo" type="primary" round icon="el-icon-edit-outline">更换手机号</el-button>
            </p>
            <p>
              <span>营业执照：</span>
              <el-upload
                class="upload-demo"
                :action="action"
                :data="params"
                :headers="headers"
                list-type="picture-card"
                :before-upload="beforeUpload"
                :on-success="success"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <el-button slot="trigger" size="small">选取文件</el-button>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </p>
          </el-col>
        </el-form>
      </el-row>
      <el-row v-show="step==2">
        <p style="text-align:center;">
          <el-button class="ebuton" type="primary" round icon="el-icon-edit-outline" @click="updateCustomer">确定</el-button>
        </p>
      </el-row>
    </div>
  </div>
</template>

<script>
import { findMyAccount, updateCustomer } from '@/api/user.js';
import userimg from '@/assets/logo/logo@3x.png';
export default {
  name: 'User',
  props: {

  },
  data: function() {
    return {
      // 用户信息
      data: {},
      userimg,
      forData: {},
      searchData: {},
      step: 1,
      // 文件信息
      headers: { token: sessionStorage.token },
      action: process.env.VUE_APP_FILEURL,
      params: {
        fileName: ''
      },
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  computed: {
    status: function() {
      const s = this.data.auditStatus;
      switch (s) {
        case 0:
          return '待审核';
        case 1:
          return '审核通过';
        case 2:
          return '审核不通过';
        default:
          return '待审核';
      }
    }
  },
  watch: {

  },
  created() {
    this.findMyAccount();
  },
  mounted() {

  },
  methods: {
    // 请求用户信息
    findMyAccount() {
      findMyAccount().then(result => {
        if (result.status == 200) {
          const data = result.data;
          this.data = data.data;
          this.$store.dispatch('setNameInfo', data.data);
        }
      });
    },
    // 编辑用户信息
    updateCustomer() {
      const reqForm = JSON.parse(JSON.stringify(this.data));
      reqForm.operationTeamIdT = reqForm.operationTeamId;
      delete reqForm.operationTeamId;
      updateCustomer(reqForm).then(result => {
        if (result.status == 200) {
          this.$store.dispatch('setNameInfo', this.data);
          this.$message({
            message: '编辑成功',
            type: 'success'
          });
          this.step = 1;
        }
      });
    },

    // 文件上传之前操作
    beforeUpload(file) {
      this.params.fileName = file.name;
    },
    success(response) {
      if (response.code == 200) {
        this.data.fileViewList.push(response.data);
        this.$message({
          message: '上传成功',
          type: 'success'
        });
      } else {
        this.$message({
          message: response.msg,
          type: 'warning'
        });
      }
    },
    initSearch() {
      this.step = 2;
    },
    changeUser() {

    },
    goto(route) {
      this.$router.push({ path: route });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};

</script>

<style scoped>
.userimg{width: 120px;height: 120px;line-height:120px;border-radius: 60px;    border: 1px solid #ccc;
    overflow: hidden;}
.userimg img{width:100%;vertical-align: middle;}
.phone-demo{    padding: 6px 15px;
    font-size: 12px;
    margin-left: 10px;
    background: #507eff;
    border: 0;}
.upload-demo{display:inline-block;}
.line p{margin:15px;}
.icon{color: #18afff;
    font-size: 24px;
    line-height: 19px;
    vertical-align: bottom;
    margin-right: 5px;}
.ebuton{min-width:130px;background:#507EFF}
</style>
